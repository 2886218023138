import React, { useState } from 'react';
import CustomSelect from '../customSelect/customSelect';
import apiService from '../../../services/apiService';
import CustomToast from '../../Toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const Details = ({userInfo}) => {

    const [selectedGender, setSelectedGender] = useState('');
    const [selectedAgeGroup, setSelectedAgeGroup] = useState('');
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const currentLang = localStorage.getItem('userLang')

    const getLangCode = (lang) => {
        switch (lang) {
            case 'English':
                return 'en';
            case 'Hindi':
                return 'hi';
            case 'Marathi':
                return 'mar';
            default:
                return 'en';
        }
    };

    const langId = getLangCode(currentLang);
    const tForLang = i18n.getFixedT(langId);
    
    const ageGroupOptions = [
        { value: 'Boomers: 1946 - 1964', label: 'Boomers: 1946 - 1964' },
        { value: 'Gen X: 1965 - 1980', label: 'Gen X: 1965 - 1980' },
        { value: 'Millenials: 1981 - 1996', label: 'Millenials: 1981 - 1996' },
        { value: 'Gen Z: 1997 - 2012', label: 'Gen Z: 1997 - 2012' },
        { value: 'Alpha: 2013 - 2025', label: 'Alpha: 2013 - 2025' },
    ];
    
    const genderOptions = [
        { value: 'Woman', label: 'Woman' },
        { value: 'Man', label: 'Man' },
        { value: 'Others', label: 'Others' },
        { value: 'Prefer not to state', label: 'Prefer not to state'},
      ];

    const handleAgeGroupChange = (value) => {
        setSelectedAgeGroup(value);
    };

    const handleGenderChange = (value) => {
        setSelectedGender(value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!selectedAgeGroup || !selectedGender) {
            CustomToast.error(tForLang('toast_messages_agegroup_gender_error'));
            return;
        }
      
        // Create FormData object
        // const formData = new FormData();
        // formData.append('gender', selectedGender);
        // formData.append('age_group', selectedAgeGroup);
        // Make API call to update user profile
        let formData = {
            gender: selectedGender,
            age_group: selectedAgeGroup,
            email: userInfo?.email || "",
            uid: userInfo?.uid // do not remove this
        }
        try {
          const token = apiService.getAuthToken();
          const response = await apiService.putWithJSON('update-org-manage-user-info', formData, token);
      
          if (response && response.status === 'success') {
            const result = await apiService.get('get-org-user-info', token);
            if(result && result.status == "success") {
                sessionStorage.setItem('user_details', JSON.stringify(result?.data))
            }
            CustomToast.success(tForLang('toast_messages_data_saved_success'))
           // console.log('Data Saved Successfully');
           setTimeout(() => {
                window.location.reload(true);
            }, 1000);
          } else {
            // Handle API error
            // setError('Failed to update profile');
            CustomToast.error(tForLang('toast_messages_update_profile_failed')); 
          }
        } catch (error) {
          
          // Handle other errors (network, etc.)
        //   setError('An error occurred while updating profile');
        //   console.error(error);
          CustomToast.error(tForLang('toast_messages_update_profile_failed')); 
          if (error == "Invalid token id!") {
            // Clear the token from local storage
            localStorage.removeItem('authToken');
            sessionStorage.removeItem('user_details');
            // Redirect to the original website
            // if (process.env.REACT_APP_ENV == "development") {
            //     window.location.href = 'https://staging.inclusional.com';
            // } else {
            //     window.location.href = 'https://site.inclusional.com';
            // }
            window.location.href = process.env.REACT_APP_LOGIN_URL
            //process.env.REACT_APP_LOGIN_URL
        }
        }
      };

    return (
        <>
            <div className="card mb-20">
                <div className="card-body p-4">

                    <form onSubmit={handleSubmit}>

                        <div className="row">
                            <div className="col-md-6 mb-lg-0 mb-lg-4 mb-3">
                                <div className="form-group age">
                                    <label htmlFor="ageSelect" className="fw-bold font-20 mb-lg-4 mb-3">
                                       {t('my_profile_user_age_group_test')}
                                    </label>
                                    <CustomSelect
                                        name="selectedAgeGroup"
                                        placeholder="Choose Your Age Group"
                                        options={ageGroupOptions}
                                        value={selectedAgeGroup}
                                        onChange={handleAgeGroupChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group gender">
                                    <label htmlFor="genderSelect" className="fw-bold font-20 mb-lg-4 mb-3">
                                        {t('my_profile_user_gender_test')}
                                    </label>
                                    <CustomSelect
                                        name="selectedGender"
                                        placeholder="Select Gender"
                                        options={genderOptions}
                                        value={selectedGender}
                                        onChange={handleGenderChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='mt-lg-5 mt-0'>
                             <button className="btn btn-md btn-primary text-right mt-4">{t('my_profile_save')}</button>
                        </div>
                        
                    </form>

                </div>
            </div>
        </>
    )

}

export default Details;