import React, { useRef, useState, useEffect } from 'react';
import ShakaPlayer from 'shaka-player-react';
import Modal from "react-modal";
import InsightModal from "../modal/insightModal";
import { useTranslation } from 'react-i18next';
import CustomToast from '../../Toast';

const CustomOverlay = ({ onSubmit, showResponse, isCorrect, currentQuestion, correctAnswer, continueToVideo, moduleContent }) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [CA, setCA] = useState(null);
    const [response, setResponse] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [showContinueButton, setShowContinueButton] = useState(false);
    const { t } = useTranslation();

    // Function to check if user's answer is correct
    const checkUserCorrectness = (questionId, selectedOptions) => {
        const question = moduleContent?.questions.find((q) => q?.qid === questionId);
        if (!question) {
            console.error(t(`toast_messages_question_with_id_not_found`));
            return false;
        }
        const correctOptions = question?.options?.filter((opt) => opt?.is_correct);
        setCA(correctOptions)
        selectedOptions = Array.isArray(selectedOptions) ? selectedOptions : [selectedOptions]
        // Check if the selected options match the correct options
        return (
            selectedOptions?.length === correctOptions?.length &&
            selectedOptions?.every((opt) => correctOptions?.some((co) => co._id === opt))
        );
    };
    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (selectedOption) {
            const isCorrect = checkUserCorrectness(currentQuestion?.qid, selectedOption);
            setResponse(isCorrect ? 'Correct!' : 'Incorrect!');
            setSubmitting(true);

            try {
                await onSubmit(isCorrect);
            } finally {
                setSubmitting(false);
            }
        }
    };

    useEffect(() => {
        let continueButtonTimeout;

        if (showResponse) {
            continueButtonTimeout = setTimeout(() => {
                setShowContinueButton(true);
            }, 1000);
        }

        return () => {
            clearTimeout(continueButtonTimeout);
        };
    }, [showResponse]);

    const handleContinueClick = () => {
        setResponse('');
        setSelectedOption(null);
        setShowContinueButton(false);
        continueToVideo();
    };

    useEffect(() => {
        let continueToVideoTimeout;

        if (showContinueButton) {
            continueToVideoTimeout = setTimeout(() => {
                continueToVideo();
            }, 5000);
        }

        return () => {
            clearTimeout(continueToVideoTimeout);
        };
    }, [showContinueButton, continueToVideo]);

    function getIndexesById(arr, idToFind) {
        const result = [];
        idToFind?.forEach(idObj => {
            arr?.forEach((obj, index) => {
                if (obj?.id === idObj?.id) {
                    result?.push(index + 1);
                }
            });
        });
        return result;
    }

    return (
        <div className="custom-overlay video-questions">
            <div className='card w-75 p-4'>
                <form className='m-3' onSubmit={handleSubmit}>
                    <h3 className='fw-bold font-26 mb-4'>{t('course_modules_time_to_check')}</h3>
                    <div className="d-flex" dangerouslySetInnerHTML={{ __html: currentQuestion?.content }} />
                    <ul className='d-flex flex-column justify-content-start mcq-options'>
                        {currentQuestion?.options?.map(Q => {
                            return <li className='mb-0'><label className={`mb-2 d-flex align-items-center ${Q.question_type === 'single_choice' ? 'radio-button-container' : 'checkbox-button-container'}`}>
                                {/* <input
                      type={currentQuestion.question_type === 'single_choice' || currentQuestion.type === 'bool' ? 'radio' : 'checkbox'}
                      name={`question_${currentQuestion._id}`}
                      value={option._id}
                    //   checked={(question?.user_answer?.find(ans => ans === option._id))}
                      onChange={(e) => {
                        const selectedOptions = currentQuestion.question_type === 'single_choice' ? e.target.value : e.target.checked
                          ? [
                            ...(userAnswers.find((ans) => ans.qid === currentQuestion.qid)?.user_answer || []),
                            option._id,
                          ]
                          : (
                            userAnswers.find((ans) => ans.qid === currentQuestion.qid)?.user_answer || []
                          ).filter((opt) => opt !== option._id);
                          handleOptionChange();
                      }}
                    /> */}
                                <input type={Q?.question_type === 'single_choice' ? 'radio' : 'checkbox'} value={Q?._id} onChange={handleOptionChange} />
                                <span class="checkmark"></span>
                                <div className="d-flex video-options" dangerouslySetInnerHTML={{ __html: Q?.content }} />
                            </label></li>
                        })}
                    </ul>
                    {showResponse && (
                        <div className='text-start my-4'>
                            <div className={isCorrect ? 'correct p-3' : 'incorrect p-3 flex-column align-items-start'}>
                                <p className='mb-0'>{response}</p>
                                <span>{!isCorrect && <p className='mb-0 text-black'>{t('course_modules_correct_answer')} <span className='fw-bold'>{t('course_modules_option')} {currentQuestion?.options?.findIndex(obj => obj.is_correct === true) + 1}</span></p>}</span>
                            </div>
                        </div>
                    )}
                    <div className='text-center'>

                        {showContinueButton ? (
                            <button className={`btn-continue text-white`} onClick={handleContinueClick}>
                                <span className='btn-continue-text'>{t('continue_to_video')}...</span>
                                <span className='loading'></span>
                            </button>
                        ) : (
                            <button className='btn btn-md btn-primary' type="submit" disabled={submitting}>
                                {submitting ? 'Submitting...' : 'Submit'}
                            </button>
                        )}
                    </div>

                </form>

            </div>
        </div>
    );
};

const CustomOverlayTimeout = ({ time, onSkip, isVideoEnd }) => {

    const handleSkip = (e) => {
        e.preventDefault();
        if (typeof onSkip === 'function') {
            onSkip();
        }
    };

    return (
        <div className="custom-overlay video-questions" style={{ zIndex: '999', background: 'rgba(0, 0, 0, 0.8)' }}>
            <div className='w-25 p-4'>
                <div className='d-flex justify-content-center align-items-start'>


                    {/* <div className='w-25'>
                    <div className="circle-progress">
                        <span class="title timer">{time}</span>
                        <div className="overlay"></div>
                        <div className="left"></div>
                        <div className="right"></div>
                    </div>
                </div>
                <div className='text-left w-75'>
                    <p className="text-white font-20 mb-0">Up next</p>
                 
                    <button style={{ color: "#f4b532" }} className="btn btn-link font-26 fw-bold px-0" onClick={handleSkip}>
                        Skip
                    </button>
                </div> */}
                </div>
            </div>
        </div>
    );
};

const Video = ({ moduleContent, updateLmsModule }) => {
    // console.log(moduleContent,"asdfghjkasdfghjkl");
    const controllerRef = useRef(null);
    const manifestUri = 'https://storage.googleapis.com/shaka-demo-assets/bbb-dark-truths-hls/hls.m3u8';
    const [insightModalIsOpen, setInsightModalIsOpen] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);
    const [showResponse, setShowResponse] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [showPlayButton, setShowPlayButton] = useState(true);
    const [videoContinued, setVideoContinued] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState({})
    const [videoEndPopup, setVideoEndPopup] = useState(false);
    const [isVideoEnd, setIsVideoEnd] = useState(true);
    const [seconds, setSeconds] = useState(5);
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const currentLang = localStorage.getItem('userLang')

    const getLangCode = (lang) => {
        switch (lang) {
          case 'English':
            return 'en'; 
          case 'Hindi':
            return 'hi';
          case 'Marathi':
            return 'mar';
          default:
            return 'en';
        }
      };

    const langId = getLangCode(currentLang);
    const tForLang = i18n.getFixedT(langId);

    const openInsightModal = () => {
        setInsightModalIsOpen(true);
    };

    const closeInsightModal = () => {
        setInsightModalIsOpen(false);
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])
    useEffect(() => {
        const { videoElement } = controllerRef?.current;

        const handleTimeUpdate = () => {
            const currentTime = videoElement?.currentTime;

            // const overlayTime = 10;
            const matchedQuestion = moduleContent?.questions.find(question => question?.video_marking === Math?.floor(currentTime));
            // setCurrentQuestion(moduleContent?.questions[0])
            // console.log(matchedQuestion);
            if (matchedQuestion && !showOverlay) {
                // if(matchedQuestion){
                // setCurrentQuestion(moduleContent?.questions[0])
                setCurrentQuestion(matchedQuestion)
                videoElement?.pause();
                // const event = new KeyboardEvent('keydown', { key: 'Escape' });

                // // Dispatch the event on the document or any target element
                // document.dispatchEvent(event);

                let fullscreen = document.getElementsByClassName("shaka-fullscreen-button")
                if (fullscreen) {
                    if (fullscreen[0]?.ariaLabel !== "Full screen") {
                        fullscreen[0]?.click();
                    }
                } else {
                    console.error('Button not found');
                }
                // videoElement.toggleFullScreen()
                setShowOverlay(true);
                // setVideoContinued(false)
            }
        };

        const handlePlay = () => {
            setShowPlayButton(false);
        };

        const handlePause = () => {
            setShowPlayButton(true);
        };

        const handleVisibilityChange = () => {
            const { videoElement } = controllerRef?.current;
            if (document.visibilityState === 'hidden') {
              setShowPlayButton(false);
              videoElement.pause();
            }
          };

        const handleVideoEnd = () => {

            setIsVideoEnd(false)
            // setVideoEndPopup(true);

            /*  const intervalId = setInterval(() => {
                  setSeconds(prevSeconds => prevSeconds - 1);
              }, 1000); */

            //    let count = 5; 
            //     setSeconds(count); 

            //     const intervalId = setInterval(() => {
            //         count--; 
            //         setSeconds(count);
            //         if (count === 0) {
            //             clearInterval(intervalId); 
            //             setVideoEndPopup(false);
            //             updateLmsModule();
            //         }
            //     }, 1000);

            // setTimeout(() => {
            //     setVideoEndPopup(false)
            //     updateLmsModule()
            //     clearInterval(intervalId);
            // }, 5000);


            let fullscreen = document.getElementsByClassName("shaka-fullscreen-button")
            if (fullscreen) {
                if (fullscreen[0]?.ariaLabel !== "Full screen") {
                    fullscreen[0]?.click();
                }
            } else {
                console.error('Button not found');
            }

        }

        videoElement?.addEventListener('timeupdate', handleTimeUpdate);
        videoElement?.addEventListener('play', handlePlay);
        videoElement?.addEventListener('pause', handlePause);
        videoElement?.addEventListener('ended', handleVideoEnd);
        // handle errors that occur after load
        // videoElement.addEventListener('error', handleError);
        // videoElement.addEventListener('ended',updateLmsModule)
        document.addEventListener("visibilitychange", handleVisibilityChange);

        return () => {
            videoElement?.removeEventListener('timeupdate', handleTimeUpdate);
            videoElement?.removeEventListener('play', handlePlay);
            videoElement?.removeEventListener('pause', handlePause);
              // handle errors that occur after load
           // videoElement.addEventListener('error', handleError);
           document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, [showOverlay]);

    const closeOverlay = () => {
        setShowOverlay(false);
        controllerRef?.current && controllerRef?.current?.videoElement?.play();
    };

    const handleQuizSubmit = (correct) => {
        setShowResponse(true);
        setIsCorrect(correct);
    };

    const continueToVideo = () => {
        setShowResponse(false);
        closeOverlay();
        setVideoContinued(true);
        setShowOverlay(false);
        setIsCorrect(false);
    };

    const togglePlayPause = () => {
        const { videoElement } = controllerRef?.current;
        //const player = new shaka.Player(videoRef.current); // Initialize Shaka Player

        if (videoElement?.paused) {
            videoElement?.play();
        } else {
            videoElement?.pause();
        }
    };

    // const handleError = (error) => {
    //     if (error instanceof Error) {
    //         console.log("slow network", error);
    //         CustomToast.error('slow network');
    //       }
      
    //     if (error.severity === shaka.util.Error.Severity.CRITICAL) {
    //         console.log("slow network", error);
    //         CustomToast.error('slow network');
           
    //     } else if(error.code === 7000) {
    //       // handle non-fatal error, playback can continue
    //       console.log("slow network", error);
    //       CustomToast.error('slow network');
    //     } else {
    //         console.log("slow network", error);
    //         CustomToast.error('slow network');
    //     }
    //   };

    // Error handler for the video player
    const handleError = (error) => {
        console.error('Shaka Player Error:', error);

         // Check if it's a media-related error (category 7, which generally refers to playback/media issues)
       if (error.severity === 2) {
            // Non-critical error: Show a warning but allow the player to continue
            if (error.category === 7 && error.code === 7000) {
                // Code 7000 typically means media loading failed (could be due to slow network)
                CustomToast.info('There was an issue loading the video. It might be due to a slow internet connection. Please try again later.');
            } else {
                // Handle other non-critical errors or warnings
                CustomToast.info(`A non-critical error occurred: Code ${error.code}. Please try again later.`);
            }
        } 
        // else if (error.code === shaka.util.Error.Code.NETWORK_ERROR) {
        //     CustomToast.info('It seems your connection is slow. Please refresh the page or try again in sometime.');
        // } 
        else {
            // For other types of errors (network, generic)
            CustomToast.info('It seems your connection is slow. Please refresh the page or try again later.');
        }
    };

    return (
        <>
             <div className="video-container">
                <ShakaPlayer ref={controllerRef} controls disablePictureInPicture controlsList="noplaybackrate nodownload" src={moduleContent?.url} onError={handleError} className="shaka-player-video" />

                {showPlayButton && (
                    <button className="play-btn" onClick={togglePlayPause}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 180 180" fill="none">
                            <g filter="url(#filter0_d_1545_54856)">
                                <path d="M77.4505 113.254V55.3722L116.038 84.3132M90.3132 20C81.8674 20 73.5044 21.6635 65.7016 24.8955C57.8987 28.1276 50.8089 32.8649 44.8369 38.8369C32.7758 50.8979 26 67.2563 26 84.3132C26 101.37 32.7758 117.728 44.8369 129.789C50.8089 135.761 57.8987 140.499 65.7016 143.731C73.5044 146.963 81.8674 148.626 90.3132 148.626C107.37 148.626 123.728 141.85 135.789 129.789C147.85 117.728 154.626 101.37 154.626 84.3132C154.626 75.8674 152.963 67.5044 149.731 59.7016C146.499 51.8987 141.761 44.8089 135.789 38.8369C129.817 32.8649 122.728 28.1276 114.925 24.8955C107.122 21.6635 98.7589 20 90.3132 20Z" fill="white" />
                            </g>
                            <defs>
                                <filter id="filter0_d_1545_54856" x="0.718277" y="0.0407453" width="179.19" height="179.19" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset dy="5.32247" />
                                    <feGaussianBlur stdDeviation="12.6409" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1545_54856" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1545_54856" result="shape" />
                                </filter>
                            </defs>
                        </svg>
                    </button>
                )}

                {showOverlay && !videoContinued && (
                    <CustomOverlay
                        onClose={closeOverlay}
                        onSubmit={handleQuizSubmit}
                        showResponse={showResponse}
                        currentQuestion={currentQuestion}
                        isCorrect={isCorrect}
                        continueToVideo={continueToVideo}
                        moduleContent={moduleContent}
                    />
                )}
                {videoEndPopup && <CustomOverlayTimeout
                    time={seconds}
                    isVideoEnd={isVideoEnd}
                    onSkip={updateLmsModule}
                    updateLmsModule={updateLmsModule}
                />}
            </div>
            <div className="row mt-4">
                <div className="col-md-12 ms-0">

                    {moduleContent?.transcript ? <p className="transcript fw-bold mb-5 primary_color">{tForLang('course_modules_transcript')} </p> : null}
                    <div className="module-content">
                        <div className="d-block" dangerouslySetInnerHTML={{ __html: moduleContent?.transcript }} />
                        {/* <div className="d-flex">
                            <p className="text-justify mb-4">Morem <span className="course-module-tooltip">ipsum <span className="tooltiptext">Morem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. </span></span>
                                dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class taciti sociosqu ad litora torquent per aptent nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.</p>
                        </div>
                        <div className="d-flex">
                            <p className="text-justify mb-4">Morem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class taciti sociosqu ad litora torquent per aptent nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.</p>
                        </div>
                        <div className="d-flex">
                            <div className="insight">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h4 className="font-18 fw-bold primary_color mb-0">Insight</h4>
                                    <a onClick={openInsightModal}> <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 37 37" fill="none">
                                        <path d="M18.6667 0.345703C8.81791 0.345703 0.759766 8.40384 0.759766 18.2527C0.759766 28.1015 8.81791 36.1597 18.6667 36.1597C28.5156 36.1597 36.5737 28.1015 36.5737 18.2527C36.5737 8.40384 28.5156 0.345703 18.6667 0.345703ZM22.2481 25.4155V20.0434C15.7837 20.0434 11.1637 22.6041 7.92256 27.2062C9.21186 20.6343 13.1872 14.1878 22.2481 12.8806V7.50849L31.2016 16.462L22.2481 25.4155Z" fill="#146B90" />
                                    </svg></a></div>

                                <Modal
                                    isOpen={insightModalIsOpen}
                                    onRequestClose={closeInsightModal}
                                    contentLabel="Insight Modal">
                                    <InsightModal />
                                </Modal>
                                <hr />
                                <p className="mb-0">Morem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. </p>
                            </div>
                            <p className="text-justify ms-4">Morem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class taciti sociosqu ad litora torquent per aptent nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.</p>
                        </div> */}
                    </div>
                </div>
               
            </div>
            <button type='button' style={{float: "right"}} disabled={isVideoEnd && !moduleContent?.is_completed} className='btn btn-md btn-primary' onClick={() => updateLmsModule()}>
                    {tForLang('course_modules_next')}
                </button>

        </>
    );
};

export default Video;








